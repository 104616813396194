import React from 'react'
import dynamic from 'next/dynamic'
import {AdditionalContent} from '../types/api-types'
const ApplicationFormPage = dynamic(() => import('./nodes/ApplicationForm'))
const SeoPage = dynamic(() => import('./nodes/SeoPage'))
const ContentPage = dynamic(() => import('./nodes/ContentPage'))
const Homepage = dynamic(() => import('./nodes/Homepage'))
const BlogPage = dynamic(() => import('./nodes/BlogPage'))
const ResourceOverview = dynamic(() => import('./nodes/ResourceOverview'))
const PillarPage = dynamic(() => import('./nodes/PillarPage'))
const EmployerDetail = dynamic(() => import('./nodes/EmployerDetail'))
const JobDetailsPage = dynamic(() => import('./nodes/JobDetailsPage'))
const OfficeOverview = dynamic(() => import('./nodes/OfficeOverview'))
const Office = dynamic(() => import('./nodes/OfficeDetails'))
const SalaryPage = dynamic(() => import('./nodes/Salary'))
const nodes: {
  [key: string]: () => React.FC<any> | React.ComponentType<any>
} = {
  'node--content_page': () => ContentPage,
  'node--homepage_jobs': () => Homepage,
  'node--blog_article': () => BlogPage,
  'node--resource_overview': () => ResourceOverview,
  'node--pillar_page': () => PillarPage,
  'node--employer_detail_page': () => EmployerDetail,
  'node--vacancy': () => JobDetailsPage,
  'node--application-form': () => ApplicationFormPage,
  'node--office_overview': () => OfficeOverview,
  'node--office': () => Office,
  'node--seo_page': () => SeoPage,
  'node--salary_page': () => SalaryPage,

}

type Props = {
  node: any
  country: string
  children: React.ReactNode
  additionalContent: AdditionalContent
}

const NodeMapper = ({node, country, children, additionalContent}: Props) => {
  if (Object.prototype.hasOwnProperty.call(nodes, node.type)) {
    const NodeComponent = nodes[node.type]()
    return (
      <NodeComponent
        page={node}
        country={country}
        additionalContent={additionalContent}
      >
        {children}
      </NodeComponent>
    )
  }
  return null
}

export default NodeMapper
