import Link from 'next/link'
import {v4 as uuidv4} from 'uuid'
import GenericLink from './Link'
import {useRouter} from 'next/router'

type Props = {
  breadCrumb: {label: string; path: string}[]
  pageType: string
}

const Breadcrumb = ({breadCrumb, pageType}: Props) => {
  const router = useRouter()
  if (!breadCrumb || breadCrumb.length === 0) {
    return null
  }
  return (
    <section
      className={`c-breadcrumb d-none d-lg-block section-box mb-0 py-3 ${
        pageType === 'node--vacancy'
          ? 'bg-slate-gray-10 c-breadcrumb__spark'
          : null
      }`}
    >
      <ol itemScope itemType="http://schema.org/BreadcrumbList">
        <li
          className="d-flex align-items-center"
          itemScope
          itemType="http://schema.org/ListItem"
          itemProp="itemListElement"
        >
          <Link prefetch={false} href="/" className="nav-link" itemProp="item">
            <meta
              itemProp="item"
              content={`${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}/${router.locale}`}
            />
            <meta itemProp="position" content="1" />
            <span itemProp="name">Home</span>
          </Link>
          <span className="c-breadcrumb__delimiter"></span>
        </li>
        {[...breadCrumb].reverse().map((breadCrumbItem, i) => {
          const id = uuidv4()
          return (
            <li
              className="d-flex align-items-center"
              key={id}
              itemScope
              itemType="http://schema.org/ListItem"
              itemProp="itemListElement"
            >
              {i === breadCrumb.length - 1 ? (
                <div className="nav-link">
                  <meta
                    itemProp="item"
                    content={`${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${breadCrumbItem.path}`}
                  />
                  <meta itemProp="position" content={String(i + 2)} />
                  <span itemProp="name">{breadCrumbItem.label}</span>
                </div>
              ) : (
                <GenericLink
                  extraClass="nav-link"
                  link={{
                    uri: {
                      alias: breadCrumbItem.path,
                      uri: '',
                    },
                  }}
                >
                  <meta
                    itemProp="item"
                    content={`${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${breadCrumbItem.path}`}
                  />
                  <meta itemProp="position" content={String(i + 2)} />
                  <span itemProp="name">{breadCrumbItem.label}</span>
                </GenericLink>
              )}
              {i < breadCrumb.length - 1 ? (
                <span className="c-breadcrumb__delimiter"></span>
              ) : null}
            </li>
          )
        })}
      </ol>
    </section>
  )
}

export default Breadcrumb
