import {ReactNode, createContext, useContext} from 'react'

type TPageInfoContext = {
  pageType: null | string
  defaultSearchQuery: undefined | string
  locationTokenQuery: undefined | string
}

type Props = TPageInfoContext & {
  children: ReactNode
}

const PageInfoContext = createContext<TPageInfoContext>({
  pageType: null,
  defaultSearchQuery: undefined,
  locationTokenQuery: undefined,
})

export function PageInfoProvider({
  children,
  pageType: basePageType,
  defaultSearchQuery: baseDefaultSearchQuery,
  locationTokenQuery: baselocationTokenQuery,
}: Props) {
  return (
    <PageInfoContext.Provider
      value={{
        pageType: basePageType,
        defaultSearchQuery: baseDefaultSearchQuery,
        locationTokenQuery: baselocationTokenQuery,
      }}
    >
      {children}
    </PageInfoContext.Provider>
  )
}

export default function usePageInfo() {
  return useContext(PageInfoContext)
}
